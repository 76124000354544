import { useEffect, useState } from "react";
import backend from "../backend";
import Container from "../components/Container";
import NewTable from "../components/NewTable";
import ConfirmPayModal from "../modals/ConfirmPayModal";
import { priceFormat } from "../utils/priceFormatter";

const PayoneerPayments = () => {
  type Headings = "user" | "amount" | "actions";
  const [payouts, setPayouts] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(false);

  const fetch = async () => {
    setIsLoading(true);
    const { data } = await backend.get("/payout/payoneer_payout");

    const formattedData = data.map((payout: any) => ({
      user: {
        value: payout.userName,
      },
      amount: {
        value: priceFormat(payout.amount),
      },
      actions: {
        isButton: true,
        onclick: () => {
          setSelectedUser({ id: payout.id, userName: payout.userName });
          setShowModal(true);
        },
        value: `Pay`,
      },
    }));

    setPayouts(formattedData);
    setIsLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <div className="py-10">
        <Container>
          <div className="">
            <h1 className="text-4xl font-bold text-gray-800">
              Pending Payoneer Payments
            </h1>

            <h4 className="mt-1 text-sm text-gray-600">
              Lorem ipsum dolor sit amet consectetur.
            </h4>
          </div>

          <div className="mt-10">
            <NewTable<Headings>
              headings={["user", "amount", "actions"]}
              datas={payouts}
            />

            {isLoading && (
              <h4 className="mt-10 text-center text-gray-600 text-xs italic">
                Fetching data...
              </h4>
            )}
          </div>
        </Container>
      </div>

      <ConfirmPayModal
        showModal={showModal}
        setShowModal={setShowModal}
        user={selectedUser}
        fetch={fetch}
      />
    </>
  );
};

export default PayoneerPayments;
