import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import backend from "../backend";
import Spinner from "../components/Spinner";
import Button from "../components/buttons/Button";

export default function ConfirmPayModal({
  showModal = false,
  setShowModal,
  user,
  fetch,
}: {
  showModal: boolean;
  setShowModal: any;
  user?: any;
  fetch?: any;
}) {
  let [isOpen, setIsOpen] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsOpen(showModal);
  }, [showModal]);

  function closeModal() {
    if (isLoading) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }

  function openModal() {
    setShowModal(true);
  }

  const pay = async () => {
    if (user.id) {
      await backend.post(`/payout/send_payout/payoneer/${user.id}`);
    }
  };

  return (
    <>
      {user && (
        <Transition appear show={showModal} as={Fragment}>
          <Dialog as="div" className="relative z-[500]" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className={`${
                  isLoading ? "pointer-events-none" : "pointer-events-auto"
                }  fixed inset-0 bg-black bg-opacity-25`}
              />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Confirm payment
                    </Dialog.Title>
                    <div className="mt-5 w-full">
                      <p className="text-sm text-gray-600">
                        Are you sure you want to initiate payment for{" "}
                        {user.userName} ?.
                      </p>
                      <div className="mt-5 w-full flex items-center justify-end">
                        <Button
                          disabled={isLoading}
                          onClick={async () => {
                            setIsLoading(true);
                            await pay();
                            await fetch();
                            setIsLoading(false);
                            setShowModal(false);
                          }}
                          variant="primary"
                          customClass="disabled:bg-brandBlue"
                        >
                          <div className="w-full min-w-[43px] flex items-center justify-center">
                            {isLoading ? (
                              <Spinner size={5} />
                            ) : (
                              <span className="text-sm font-medium">
                                Confirm Pay
                              </span>
                            )}
                          </div>
                        </Button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
}
