type SpinnerType = "white" | "dark" | undefined;
const Spinner = ({
  variant = "white",
  size = 7,
}: {
  variant?: SpinnerType;
  size?: number;
}) => {
  return (
    <div
      style={{
        width: `${(size * 4) / 16}rem`,
        height: `${(size * 4) / 16}rem`,
      }}
      className={`animate-spin rounded-full border-2
      ${variant === "white" && " border-t-white border-white/30"}
      ${variant === "dark" && " border-t-gray-800 border-white/30"}
      `}
    ></div>
  );
};

export default Spinner;
