import Spinner from "../Spinner";

type Variant =
  | "primary"
  | "red"
  | "white"
  | "secondary"
  | "dark"
  | "general"
  | "vooaxis";

type ButtonType = "button" | "submit" | "reset" | "submit" | undefined;

const Button = ({
  type = "button",
  variant = "primary",
  onClick,
  widthFull = false,
  children,
  customClass = "",
  disabled = false,
  loading = false,
}: {
  loading?: boolean;
  disabled?: boolean;
  customClass?: string;
  type?: ButtonType;
  onClick?: any;
  variant?: Variant;
  widthFull?: boolean;
  children: any;
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`${customClass} disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed ${
        widthFull ? "w-full" : "w-max"
      } px-3 py-1 font-medium text-sm text-center rounded-md 
    ${
      variant === "red" &&
      "bg-brandRed hover:bg-brandRedLight text-white outline-none focus:visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-offset-white focus-visible:ring-brandRed"
    }
    ${
      variant === "white" &&
      "bg-white hover:bg-whiteLight text-gray-700 outline-none focus:visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-offset-white focus-visible:ring-white"
    }
    ${
      variant === "primary" &&
      "bg-brandBlue hover:bg-brandBlueLight text-white outline-none focus:visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-offset-white focus-visible:ring-brandBlue"
    }
    ${
      variant === "vooaxis" &&
      "bg-vooaxisGreen hover:bg-vooaxisGreen/90 text-white outline-none focus:visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-offset-white focus-visible:ring-voobg-vooaxisGreen"
    }
    ${
      variant === "dark" &&
      "bg-brandBlack hover:bg-brandBlack/90 text-white outline-none focus:visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-offset-white focus-visible:ring-brandBlack"
    }
    ${
      variant === "secondary" &&
      "bg-gray-200 hover:bg-gray-200/80 text-gray-600 outline-none focus:visible:outline-none focus-visible:ring-offset-1 focus-visible:ring-offset-white focus-visible:ring-2 focus-visible:ring-brandGraySelected"
    }
    ${
      variant === "general" &&
      " outline-none focus:visible:outline-none focus-visible:ring-offset-1 focus-visible:ring-offset-white focus-visible:ring-2 focus-visible:ring-brandGraySelected"
    }
    `}
    >
      {loading ? (
        <div className="py-1 flex items-center justify-center">
          <Spinner size={4} />
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
