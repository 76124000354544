import React, { useEffect, useRef } from "react";
import videojs from "video.js";

interface IVideoProps
  extends React.DetailedHTMLProps<
    React.VideoHTMLAttributes<HTMLVideoElement>,
    HTMLVideoElement
  > {
  mute?: boolean;
  poster?: string;
  src?: string;
  options?: any;
  setPlayer?: any;
  className?: string;
}

const Video = ({
  src,
  className,
  poster,
  mute = false,
  options = {
    preload: true,
    controls: true,
    fluid: true,
    controlBar: {
      liveDisplay: true,
      progressControl: false,
      playToggle: false,
      muteToggle: true,
      volumeControl: false,
      volumePanel: false,
      volumeMenuButton: true,
      pictureInPictureToggle: false,
    },
  },
  setPlayer,
  ...rest
}: IVideoProps) => {
  const videoRef = useRef(null) as any;
  const playerRef = useRef(null) as any;

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(
        videoElement,
        options,
        () => {}
      ));
    } else {
      // you can update player here [update player through props]
      //const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;
    setPlayer(player);
  }, [playerRef]);

  return (
    <div data-vjs-player className={`w-full h-full ${className}`}>
      <video
        ref={videoRef}
        muted={mute}
        preload="none"
        disablePictureInPicture
        controlsList="nodownload"
        className="video-js vjs-theme-forest rounded-lg overflow-hidden"
        poster={poster}
        {...rest}
      >
        <source src={src} type="video/mp4" className="aspect-video" />
      </video>
    </div>
  );
};

export default Video;
