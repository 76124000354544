import axios from "axios";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

httpClient.interceptors.request.use(function (config: any) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default httpClient;
