import gsap from "gsap";
import parse from "html-react-parser";
import { customAlphabet } from "nanoid";
import React, { useEffect, useRef, useState } from "react";
const nanoid = customAlphabet(
  "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  10
);
const TextInput = ({
  name = "",
  placeholder = "",
  type = "text",
  icon,
  label,
  error,
  value,
  onBlur,
  isTouched,
  onChange,
  additionalInfo,
  min = 0,
  max = 10000,
  maxLength,
  pattern,
  onInput,
  numberValidate = false,
  disabled = false,
}: {
  disabled?: boolean;
  numberValidate?: boolean;
  onInput?: any;
  maxLength?: number;
  pattern?: string;
  min?: number;
  max?: number;
  name: string;
  label?: string;
  placeholder?: string;
  type?: string;
  additionalInfo?: string;
  icon?: string;
  error?: string;
  value?: any;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  isTouched?: boolean;
}) => {
  const input_container = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (error) {
      input_container.current?.classList.add("error");
      const errEl = document.querySelectorAll(
        ".error > input"
      )[0] as HTMLElement;
      errEl.focus();

      gsap.to(input_container.current, {
        x: 3,
        yoyo: true,
        repeat: 7,
        duration: 0.05,
      });
    } else {
      input_container.current?.classList.remove("error");
    }
  }, [error, input_container.current]);

  const [id, setId] = useState<any>();

  useEffect(() => {
    setId(nanoid());
  }, []);

  const numberValidator = (e: any) => {
    const invalidChars = ["-", "+", "e"];
    const key = e.nativeEvent.data;
    if (invalidChars.includes(key)) {
      e.preventDefault();
      e.target.value = e.target.value.replace(/[e\+\-]/gi, "");
    }
    if (parseInt(e.target.value) > 1000) {
      e.target.value = parseInt(e.target.value.slice(0, e.target.maxLength));
    }
  };

  return (
    <>
      {id && (
        <div className="w-full">
          {label && (
            <label
              htmlFor={id.toLowerCase()}
              className="inline-flex flex-shrink-0 whitespace-nowrap gap-2 mb-2 text-sm font-medium text-gray-800"
            >
              {label}

              {additionalInfo && (
                <div className="">
                  <span className="italic whitespace-pre-wrap text-[10px] text-gray-500">
                    <sup>*</sup>
                    {additionalInfo}
                  </span>
                </div>
              )}
            </label>
          )}
          <div ref={input_container} className="w-full relative">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              {icon && parse(icon)}
            </div>
            <input
              min={min}
              maxLength={maxLength}
              max={max}
              pattern={pattern}
              type={type}
              value={value}
              onInput={numberValidate ? numberValidator : () => {}}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
              id={id.toLowerCase()}
              className={`${icon && "pl-10"} w-full px-3 py-2 bg-white border ${
                error ? "border-red-300" : "border-gray-300"
              }  text-gray-900 text-sm rounded-lg focus:ring-2 disabled:bg-gray-200 focus:ring-offset-1 focus:outline-none transition-[box-shadow,border] duration-300 ${
                error
                  ? "ring-2 ring-offset-1 ring-red-500 focus:ring-red-500"
                  : "focus:ring-blue-500"
              }`}
              placeholder={placeholder}
            />
          </div>
          <div className="mt-1 text-sm text-red-600">{error}</div>
        </div>
      )}
    </>
  );
};

export default TextInput;
