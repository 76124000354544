import { Formik } from "formik";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import * as yup from "yup";
import Button from "../components/buttons/Button";
import TextInput from "../components/inputs/TextInput";
import { useAuth } from "../context/authContext";
import { userLoadingState } from "../store/auth";

interface ISignInProps {}

const SignIn = ({}: ISignInProps) => {
  const [userLoading, setUserLoading]: any = useRecoilState(userLoadingState);

  const { currentUser, signIn } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser]);

  const signInSchema = yup.object({
    userNameOrEmail: yup.string().required("Email or Username is required"),
    password: yup
      .string()
      .trim()
      .required("Please Enter your password")
      .min(8, "Must be 8 characters or more")
      .matches(/[a-z]+/, "One lowercase character")
      .matches(/[A-Z]+/, "One uppercase character")
      .matches(/[@$!%*#?&]+/, "One special character")
      .matches(/\d+/, "One number"),
  });

  const handleSignin = async ({
    userNameOrEmail,
    password,
  }: {
    userNameOrEmail: string;
    password: string;
  }) => {
    signIn({ userNameOrEmail, password });
  };

  return (
    <div className="h-screen flex items-center justify-center max-w-sm mx-auto">
      <div className="w-full max-w-xl mx-auto">
        {!userLoading && (
          <Formik
            initialValues={{
              userNameOrEmail: "",
              password: "",
            }}
            validationSchema={signInSchema}
            onSubmit={async (
              values,
              { setSubmitting, resetForm, setErrors }
            ) => {
              try {
                setSubmitting(true);
                await handleSignin(values);
                setSubmitting(false);
              } catch (error) {
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form className="" onSubmit={handleSubmit}>
                <div className="w-full flex flex-col gap-5">
                  <TextInput
                    name="Username or Email"
                    placeholder="Username or Email"
                    label="Username or Email"
                    value={values.userNameOrEmail}
                    isTouched={touched.userNameOrEmail}
                    onChange={handleChange("userNameOrEmail")}
                    onBlur={handleBlur("userNameOrEmail")}
                    error={
                      errors.userNameOrEmail &&
                      touched.userNameOrEmail &&
                      errors.userNameOrEmail
                        ? errors.userNameOrEmail
                        : ""
                    }
                  />

                  <div className="relative">
                    <h3 className="absolute top-0 right-0 hover:underline cursor-pointer ml-auto -mb-4 text-xs text-brandLink">
                      Forgot Password?
                    </h3>

                    <TextInput
                      name="Password"
                      type="password"
                      placeholder="Password"
                      label="Password"
                      value={values.password}
                      isTouched={touched.password}
                      onChange={handleChange("password")}
                      onBlur={handleBlur("password")}
                      error={
                        errors.password && touched.password && errors.password
                          ? errors.password
                          : ""
                      }
                    />
                  </div>
                </div>

                <div className="mt-10">
                  <Button
                    loading={isSubmitting}
                    type="submit"
                    widthFull={true}
                    variant="dark"
                  >
                    <div className="py-2 text-sm font-medium">
                      Sign in to your account
                    </div>
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default SignIn;
