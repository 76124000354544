const timeToSec = (time: string) => {
  const min = parseInt(time.split(":")[0]);
  const sec = parseInt(time.split(":")[1]);
  const seconds = min * 60 + sec;
  return seconds;
};

const secToTime = (sec: number) => {
  let seconds: string | number = sec % 60;
  const mins = (sec - seconds) / 60;

  if (seconds === 0) {
    seconds = seconds.toString() + "0";
  }
  const time = `${mins}:${seconds}`;
  return time;
};

export { timeToSec, secToTime };
