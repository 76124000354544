import { Link, useNavigate } from "react-router-dom";
import { PrimaryBtn } from "../components/buttons/Buttons";
import { useAuth } from "../context/authContext";

export const Home = () => {
  const navigate = useNavigate();
  const { currentUser, isLoading } = useAuth();
  return (
    <>
      {!isLoading && (
        <div className="w-full min-h-screen flex items-center justify-center gap-5">
          <div className="">
            {!currentUser && (
              <Link to="/signin">
                <PrimaryBtn className="bg-gray-800">Sign in</PrimaryBtn>
              </Link>
            )}
          </div>
          {currentUser && (
            <a href="/update_product_queue">
              <PrimaryBtn
                onClick={() => navigate("/update_product_queue")}
                className="bg-gray-800"
              >
                update product queue
              </PrimaryBtn>
            </a>
          )}
          {currentUser && (
            <a href="/under_review">
              <PrimaryBtn
                onClick={() => navigate("/under_review")}
                className="bg-gray-800"
              >
                under review
              </PrimaryBtn>
            </a>
          )}
          {currentUser && (
            <a href="/payoneer">
              <PrimaryBtn
                onClick={() => navigate("/payoneer")}
                className="bg-gray-800"
              >
                payoneer
              </PrimaryBtn>
            </a>
          )}
        </div>
      )}
    </>
  );
};
