import { atom } from "recoil";

export const userState = atom({
  key: "userState",
  default: null,
});

export const userLoadingState = atom({
  key: "userLoadingState",
  default: false,
});
