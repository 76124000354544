import React from "react";
import { Popover } from "@headlessui/react";
import { Placement } from "@popperjs/core";
import { useState } from "react";
import { usePopper } from "react-popper";
import { Link } from "react-router-dom";

type Option = {
  type: "button" | "link" | "divider" | "context_menu";
  name?: string;
  url?: string;
  subOptions?: Option[];
};

interface IContextMenuProps {
  placement: Placement;
  children: React.ReactNode;
  options: Option[];
  isSubContext?: boolean;
}

const ContextMenu = ({
  options,
  placement,
  children,
  isSubContext = false,
}: IContextMenuProps) => {
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
  });
  return (
    <>
      <Popover>
        {({ open }) => (
          <>
            <Popover.Button
              as="div"
              ref={setReferenceElement}
              className={`mb-1 ${
                isSubContext ? "w-full rounded-md" : "w-max rounded-full"
              } ${open && "bg-gray-300"}`}
            >
              {children}
            </Popover.Button>

            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              as="ul"
              className={` ${
                isSubContext ? "" : "-mt-10"
              } p-1 border border-gray-300 rounded-lg bg-gray-100/60 before:content-[''] before:absolute before:inset-0 before:backdrop-blur-xl before:-z-20 before:rounded-lg`}
            >
              {(close) => (
                <>
                  {options &&
                    options.length &&
                    options.map((option: Option, idx: number) => (
                      <>
                        {(option.type === "button" ||
                          option.type === "link") && (
                          <li className="w-full whitespace-nowrap text-left text-sm px-3 py-0.5 rounded-md hover:bg-brandBlue hover:text-white text-gray-700">
                            {option.type === "button" && (
                              <button>{option.name}</button>
                            )}

                            {option.type === "link" && (
                              <Link to="#">
                                <a className="w-full block">{option.name}</a>
                              </Link>
                            )}
                          </li>
                        )}

                        {option.type === "divider" && (
                          <div className="px-3">
                            <div className="w-full border-t my-1 border-gray-300"></div>
                          </div>
                        )}

                        {option.type === "context_menu" && (
                          <ContextMenu
                            isSubContext={true}
                            options={option.subOptions ? option.subOptions : []}
                            placement="right-start"
                          >
                            <p className="w-full flex items-center justify-between gap-3 text-left text-sm px-3 pr-[0.45rem] py-0.5 rounded-md hover:bg-brandBlue hover:text-white text-gray-700">
                              {option.name}
                              <span>
                                <svg
                                  aria-hidden="true"
                                  className="w-4 h-4"
                                  preserveAspectRatio="xMidYMid meet"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1"
                                    d="M5.75 12.25L10.25 8l-4.5-4.25"
                                  />
                                </svg>
                              </span>
                            </p>
                          </ContextMenu>
                        )}
                      </>
                    ))}
                </>
              )}
            </Popover.Panel>
          </>
        )}
      </Popover>
    </>
  );
};

export default ContextMenu;
