import { useEffect, useState } from "react";

const Tabs = ({
  tabs,
  widthFull,
  selectedTab,
  setSelectedTab,
  customClass = "",
}: {
  tabs: any[];
  widthFull?: boolean;
  selectedTab?: string;
  setSelectedTab?: any;
  customClass?: string;
}) => {
  const [selected, setSelected] = useState("");

  useEffect(() => {
    if (selectedTab) {
      setSelected(selectedTab);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (setSelectedTab && selected) {
      setSelectedTab(selected);
    }
  }, [selected]);

  return (
    <div
      className={`${customClass} rounded-md border flex flex-wrap md:flex-nowrap items-center justify-center`}
    >
      {tabs.map((tab, idx) => (
        <button
          key={tab.id}
          onClick={() => {
            setSelected(tab.name);
          }}
          className={`${
            widthFull ? "md:w-full" : "md:w-auto"
          } px-4 py-[2px] w-full whitespace-nowrap  text-xs md:text-sm rounded-[5px] ${
            selectedTab === tab.name
              ? "text-gray-800 bg-gray-200"
              : "text-gray-500"
          } `}
        >
          {tab.name}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
