import { format } from "date-fns";
import parse from "html-react-parser";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import backend from "../backend";
import Container from "../components/Container";
import Spinner from "../components/Spinner";
import Button from "../components/buttons/Button";
import ContextMenu from "../components/inputs/ContextMenu";
import Tabs from "../components/inputs/Tabs";
import TextArea from "../components/inputs/TextArea";
import TextInput from "../components/inputs/TextInput";
import Video from "../components/inputs/Video";
import getProductInformations from "../utils/getProductInformations";

export const History = ({ authorId }: { authorId: any }) => {
  type History = {
    openReply?: boolean;
    threads: {
      id: string;
      userName: string;
      avatar: string;
      badge: null | {
        name: string;
      };
      date: string;
      history: string;
    }[];
  };

  const [histories, setHistories] = useState<History[]>([]);

  const [query] = useSearchParams();

  const [unprocessedHistories, setUnprocessedHistories] = useState([]);

  const getHistory = async () => {
    const { data: history } = await backend.get(
      `/history/${query.get("originalId")}`
    );
    setUnprocessedHistories(history);
  };

  const getThread = (history: any) => {
    return {
      id: history.id,
      avatar: "https://s3.envato.com/files/279864614/2018-avatar-sq-80x80.jpg",
      history: history.history,
      userName: history?.user?.userName,
      badge: history.userId === authorId ? { name: "Author" } : null,
      date: format(new Date(history.createdAt), "dd MMMM yyyy"),
    };
  };

  const push = (val: any, arr: any = []) => {
    arr.push(getThread(val));
    if (val?.replies?.length) {
      val?.replies.forEach((reply: any) => {
        push(reply, arr);
      });
    }
    return arr;
  };

  const processHistories = (histories: any) => {
    let main: any = [];
    Object.entries(histories).forEach(([key, val]: any) => {
      main.push({ threads: push(val) });
    });
    return main;
  };

  useEffect(() => {
    getHistory();
  }, []);

  useEffect(() => {
    if (unprocessedHistories) {
      setHistories(processHistories(unprocessedHistories));
    }
  }, [unprocessedHistories]);

  return (
    <div className="pb-5 border rounded-md">
      <div className="w-full p-5 flex flex-col md:flex-row items-center justify-between gap-5 border-b">
        <h2 className="whitespace-nowrap font-bold text-xl text-gray-800">
          {histories.length} history{histories.length > 1 && "s"} found
        </h2>

        <div className="w-full md:max-w-xs">
          <TextInput
            name="search histories"
            placeholder="Search..."
            type="search"
            icon={`<svg className="w-5 h-5 text-gray-600" aria-hidden="true" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"><path d="m11.25 11.25l3 3"/><circle cx="7.5" cy="7.5" r="4.75"/></g></svg>`}
          />
        </div>
      </div>
      {histories &&
        histories.map((history, history_idx) => (
          <div
            key={history_idx}
            className={`pl-10 pr-5 pt-6 ${
              histories.length - 1 !== history_idx && "pb-5 border-b"
            } `}
          >
            <ol className="relative border-l border-gray-300">
              {history.threads.map((thread, idx) => (
                <li key={idx} className="mb-10 ml-7">
                  <span className="p-1 flex absolute -left-5 justify-center items-center bg-blue-100 rounded-full">
                    <img
                      className="w-8 h-8 rounded-full object-cover"
                      src={thread.avatar}
                      alt="user profile pic"
                    />
                  </span>
                  <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900">
                    {thread.userName}{" "}
                    {thread.badge && (
                      <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ml-3">
                        {thread.badge.name}
                      </span>
                    )}
                  </h3>
                  <time className="block mb-3 text-xs font-normal leading-none text-gray-500">
                    {thread.date}
                  </time>
                  <p className="mb-4 text-sm font-normal text-gray-500">
                    {thread.history}
                  </p>

                  {history.threads.length - 1 === idx && (
                    <span className="p-3 -mt-5 flex absolute -left-4 justify-center items-center bg-blue-100 rounded-full">
                      <div className="w-2 h-2 rounded-full bg-brandBlue"></div>
                    </span>
                  )}
                </li>
              ))}
            </ol>
          </div>
        ))}

      {/* {isHistoryLoading && (
        <div className="pt-10 pb-8 w-full flex items-center justify-center">
          <Spinner variant="dark" size={10} />
        </div>
      )} */}
    </div>
  );
};

const ItemDetails = ({
  product,
  originalProduct,
}: {
  product: any;
  originalProduct: any;
}) => {
  const [player, setPlayer] = useState<any>(null);
  const [isPlaying, setIsPlaying] = useState<any>(false);
  useEffect(() => {
    if (player) {
      player.on("play", () => {
        setIsPlaying(true);
      });
      player.on("ended", () => {
        setIsPlaying(false);
      });
      player.on("pause", () => {
        setIsPlaying(false);
      });
    }
  }, [player]);

  const [updatePlayer, setUpdatePlayer] = useState<any>(null);
  const [isUpdatePlaying, setIsUpdatePlaying] = useState<any>(false);
  useEffect(() => {
    if (updatePlayer) {
      updatePlayer.on("play", () => {
        setIsUpdatePlaying(true);
      });
      updatePlayer.on("ended", () => {
        setIsUpdatePlaying(false);
      });
      updatePlayer.on("pause", () => {
        setIsUpdatePlaying(false);
      });
    }
  }, [updatePlayer]);
  return (
    <>
      {/* video */}
      <div className="relative w-full shadow-xl">
        <div className="absolute top-0 right-0 m-3">
          <ContextMenu
            placement="bottom-end"
            options={[
              {
                type: "button",
                name: "Add To Cart",
              },
              {
                type: "button",
                name: "Download Preview",
              },
              {
                type: "button",
                name: "Add to collections",
              },
              {
                type: "divider",
              },
              {
                type: "context_menu",
                name: "Share",
                subOptions: [
                  {
                    type: "button",
                    name: "Copy Link",
                  },
                  {
                    type: "link",
                    url: "https://facebook.com",
                    name: "Facebook",
                  },
                  {
                    type: "link",
                    url: "https://instagram.com",
                    name: "Instagram",
                  },
                  {
                    type: "link",
                    url: "https://whatsapp.com",
                    name: "Whatsapp",
                  },
                ],
              },
            ]}
          >
            <button className="p-1 rounded-full bg-gray-300">
              <svg
                aria-hidden="true"
                className="w-4 h-4"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
              >
                <path
                  fill="currentColor"
                  d="M9.5 13a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0z"
                />
              </svg>
            </button>
          </ContextMenu>
        </div>
        <div className="group relative w-full aspect-video rounded-lg overflow-hidden">
          {!isPlaying ? (
            <div className="mb-10 hidden absolute z-20 inset-0 group-hover:flex items-center justify-center">
              <button
                onClick={() => player.play()}
                className="mt-10 p-3 rounded-full backdrop-blur-sm bg-white/10 hover:bg-white/20"
              >
                <svg
                  className="w-10 h-10 pl-1 text-white"
                  aria-hidden="true"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="currentColor"
                    d="m11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
                  />
                </svg>
              </button>
            </div>
          ) : (
            <div className="mb-10 hidden absolute z-20 inset-0 group-hover:flex items-center justify-center">
              <button
                onClick={() => player.pause()}
                className="mt-10 p-3 rounded-full backdrop-blur-sm bg-white/10 hover:bg-white/20"
              >
                <svg
                  className="w-10 h-10 text-white"
                  aria-hidden="true"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="currentColor"
                    d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z"
                  />
                </svg>
              </button>
            </div>
          )}

          <Video
            setPlayer={setPlayer}
            poster={product.previewImageUrl}
            src={product.previewVideoUrl}
            options={{
              preload: true,
              controls: true,
              fluid: true,
              controlBar: {
                liveDisplay: false,
                progressControl: true,
                playToggle: true,
                muteToggle: false,
                volumeControl: false,
                volumePanel: true,
                volumeMenuButton: false,
                pictureInPictureToggle: false,
              },
            }}
          />
        </div>
      </div>
      {/* end of video */}

      <h1 className="my-5 py-5 text-base font-bold text-gray-800 border-b border-t">
        Updated product
      </h1>

      {/* video */}
      {originalProduct.previewVideoUrl && (
        <div className="relative w-full shadow-xl">
          <div className="group relative w-full aspect-video rounded-lg overflow-hidden">
            {!isUpdatePlaying ? (
              <div className="mb-10 hidden absolute z-20 inset-0 group-hover:flex items-center justify-center">
                <button
                  onClick={() => updatePlayer.play()}
                  className="mt-10 p-3 rounded-full backdrop-blur-sm bg-white/10 hover:bg-white/20"
                >
                  <svg
                    className="w-10 h-10 pl-1 text-white"
                    aria-hidden="true"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      d="m11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
                    />
                  </svg>
                </button>
              </div>
            ) : (
              <div className="mb-10 hidden absolute z-20 inset-0 group-hover:flex items-center justify-center">
                <button
                  onClick={() => updatePlayer.pause()}
                  className="mt-10 p-3 rounded-full backdrop-blur-sm bg-white/10 hover:bg-white/20"
                >
                  <svg
                    className="w-10 h-10 text-white"
                    aria-hidden="true"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z"
                    />
                  </svg>
                </button>
              </div>
            )}

            <Video
              setPlayer={setUpdatePlayer}
              poster={
                originalProduct.previewImageUrl || product.previewImageUrl
              }
              src={originalProduct.previewVideoUrl}
              options={{
                preload: true,
                controls: true,
                fluid: true,
                controlBar: {
                  liveDisplay: false,
                  progressControl: true,
                  playToggle: true,
                  muteToggle: false,
                  volumeControl: false,
                  volumePanel: true,
                  volumeMenuButton: false,
                  pictureInPictureToggle: false,
                },
              }}
            />
          </div>
        </div>
      )}

      {originalProduct.previewImageUrl && (
        <div className="w-full h-full aspect-video">
          <img
            src={originalProduct.previewImageUrl}
            className="w-full h-full rounded-md object-cover"
            alt=""
          />
        </div>
      )}
      {/* end of video */}
      {/* html content */}
      <div className="w-full mt-10 content_html text-sm prose text-gray-800">
        {parse(product.description)}
      </div>
      {/* end of html content */}
    </>
  );
};

const UpdateQueueItem = () => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { id } = useParams();
  const [product, setProduct] = useState<any>(null);
  const [updatedProduct, setUpdatedProduct] = useState<any>(null);
  const getProduct = async () => {
    const { data: product } = await backend.get(
      `/products/admin_product/${query.get("originalId")}`
    );
    setProduct(product);
  };

  const getUpdatedProduct = async () => {
    const { data: updated_product } = await backend.get(
      `/update_product_queue/get_single_product/${id}`
    );
    setUpdatedProduct(updated_product);
  };

  useEffect(() => {
    getProduct();
    getUpdatedProduct();
  }, []);

  const informations = getProductInformations(product);

  const [statusLoading, setStatusLoading] = useState(false);

  const [message, setMessage] = useState("");

  const changeStatus = async (status: string) => {
    setStatusLoading(true);
    await backend.post("/history/", {
      productId: id,
      parentId: null,
      history: message,
    });

    await backend.patch("/products/change_product_status", {
      id,
      status,
    });
    setStatusLoading(false);
    navigate("/under_review");
  };

  const tabs = [
    { id: nanoid(5), name: "Item Details" },
    {
      id: nanoid(5),
      name: "History",
    },
  ];

  const [selectedTab, setSelectedTab] = useState("Item Details");

  const downloadMainFile = async (id: string) => {
    const { data: url } = await backend.get(`products/admin_product_url/${id}`);
    if (!url) {
      alert("Invalid product");
      return;
    }

    const element = document.createElement("a");

    element.setAttribute("download", "file");
    element.setAttribute("href", url);
    element.style.display = "none";

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const approveUpdate = async () => {
    setStatusLoading(true);
    if (message.length) {
      await backend.post("/history/", {
        productId: query.get("originalId"),
        parentId: null,
        history: message,
      });
    }

    await backend.post(`/update_product_queue/approve_product_in_update/${id}`);

    await backend.patch("/products/change_product_status", {
      id: query.get("originalId"),
      status: "PUBLISHED",
    });
    setStatusLoading(false);
    navigate("/update_product_queue");
  };

  const rejectUpdate = async () => {
    setStatusLoading(true);
    if (message.length) {
      await backend.post("/history/", {
        productId: query.get("originalId"),
        parentId: null,
        history: message,
      });
    }
    await backend.delete(
      `/update_product_queue/reject_product_in_update/${id}`
    );
    setStatusLoading(false);
    navigate("/update_product_queue");
  };

  return (
    <>
      {product && (
        <>
          <Container>
            <div className="my-8 w-full flex flex-col items-center md:items-start justify-center flex-shrink-0">
              <h1 className="text-2xl md:text-4xl text-gray-800 font-bold">
                {product.name}
              </h1>

              <div className="mt-2 flex md:flex-row flex-col items-center justify-start gap-2 md:gap-10">
                <h4 className="text-sm text-gray-800 whitespace-nowrap">
                  By
                  <Link to="#">
                    <span className="ml-2 cursor-pointer text-brandLink hover:text-brandBlue hover:underline">
                      {product.author.userName}
                    </span>
                  </Link>
                </h4>

                <h3 className="text-sm text-gray-800 whitespace-nowrap">
                  {product.category} - {product.subCategory}
                </h3>
              </div>
            </div>
          </Container>

          <div className="w-full">
            <Container>
              <div className="w-full flex flex-col lg:flex-row items-start justify-between gap-14">
                {/* left */}
                <div className="w-full">
                  {/* tabs */}
                  <div className="mb-5 w-full">
                    <Tabs
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                      widthFull={true}
                      tabs={tabs}
                    />
                  </div>
                  {/* end of tabs */}
                  {selectedTab === "Item Details" && (
                    <ItemDetails
                      originalProduct={updatedProduct}
                      product={product}
                    />
                  )}
                  {selectedTab === "History" && (
                    <History authorId={product.author.id} />
                  )}
                </div>
                {/* end of left */}

                {/* right */}
                <div className="w-full lg:w-[21.875rem]">
                  <div className="">
                    {updatedProduct && (
                      <div className="w-full mb-5 py-5 bg-white border rounded-lg flex flex-col">
                        {updatedProduct.name &&
                          updatedProduct.name !== product.name && (
                            <div
                              className={`text-sm flex px-5 py-3 items-start justify-between gap-10`}
                            >
                              <h3 className="text-gray-800 w-36 font-semibold">
                                Updated Name
                              </h3>
                              <h6 className="text-gray-600 w-48">
                                {updatedProduct.name}
                              </h6>
                            </div>
                          )}

                        {updatedProduct.tags &&
                          updatedProduct.tags !== product.tags && (
                            <div
                              className={`text-sm flex px-5 py-3 items-start justify-between gap-10 bg-gray-100/40`}
                            >
                              <h3 className="text-gray-800 w-36 font-semibold">
                                Updated tags
                              </h3>
                              <h6 className="text-gray-600 w-48">
                                {updatedProduct.tags.join(", ")}
                              </h6>
                            </div>
                          )}
                      </div>
                    )}
                    <div className="mb-5 w-full flex flex-col items-center justify-start gap-2">
                      <Button widthFull={true} variant="secondary">
                        View Product
                      </Button>
                      <Button
                        onClick={() => downloadMainFile(product.id)}
                        widthFull={true}
                        variant="dark"
                      >
                        Download Original ZIP
                      </Button>
                      {updatedProduct.mainFileRef && (
                        <Button
                          onClick={() => downloadMainFile(updatedProduct.id)}
                          widthFull={true}
                          variant="dark"
                        >
                          Download Updated ZIP
                        </Button>
                      )}
                    </div>

                    <TextArea
                      name="message"
                      placeholder="Enter message"
                      additionalInfo="Can be empty"
                      label="Message to author"
                      onChange={(e: any) => setMessage(e.target.value)}
                    />
                    {!statusLoading ? (
                      <div className="w-full flex flex-col items-center justify-start gap-2">
                        <Button
                          customClass="bg-green-400 hover:bg-green-500"
                          widthFull={true}
                          variant="general"
                          onClick={approveUpdate}
                        >
                          <span className="py-2 text-green-900">
                            Approve Updates
                          </span>
                        </Button>
                        <Button
                          customClass="bg-red-700 hover:bg-red-800"
                          widthFull={true}
                          variant="general"
                          onClick={rejectUpdate}
                        >
                          <span className="text-white">Reject Updates</span>
                        </Button>
                      </div>
                    ) : (
                      <div className="mt-10 w-full flex items-center justify-center">
                        <Spinner variant="dark" />
                      </div>
                    )}

                    <div className="w-full mt-16 my-5 py-5 bg-white border rounded-lg flex flex-col">
                      {informations.map((info, idx) => (
                        <div
                          key={idx}
                          className={`${
                            idx % 2 === 0 && "bg-gray-100/40"
                          } text-sm flex px-5 py-3 items-start justify-between gap-10`}
                        >
                          <h3 className="text-gray-800 w-36 font-semibold">
                            {info.title}
                          </h3>
                          <h6 className="text-gray-600 w-48">{info.value}</h6>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* end of right */}
              </div>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default UpdateQueueItem;
