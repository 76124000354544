import parse from "html-react-parser";
import { Fragment } from "react";
import Button from "./buttons/Button";

const Table = ({
  headings = [],
  datas = [],
}: {
  headings: any[];
  datas: any[];
}) => {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100">
          <tr>
            {headings.map((heading, idx) => (
              <th key={idx} scope="col" className="px-6 py-3">
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
            >
              Apple MacBook Pro 17"
            </th> */}
          {datas.map((data, idx) => (
            <tr key={idx} className="bg-white border-b">
              {Object.keys(data).map((key, idx) => (
                <Fragment key={idx}>
                  {data[key].button ? (
                    <div className="px-6 py-4">
                      <Button
                        customClass={`${data[key].class && data[key].class}`}
                        onClick={data[key].onClick}
                      >
                        {data[key].value}
                      </Button>
                    </div>
                  ) : (
                    <td
                      key={idx}
                      className={`${
                        data[key].class && data[key].class
                      } px-6 py-4
                                } `}
                    >
                      {data[key].badge ? (
                        <span className="bg-gray-100 p-1 px-2 rounded-md">
                          {data[key].value}
                        </span>
                      ) : (
                        parse(data[key].value)
                      )}
                    </td>
                  )}
                </Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
