import React from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";

const NewSelect = ({
  opts = [],
  name = "",
  label,
  error,
  value,
  onBlur,
  isTouched,
  onChange,
  additionalInfo,
  position = "bottom",
  functionToRunOnChange = () => {},
}: {
  functionToRunOnChange?: any;
  opts: any[];
  name?: string;
  label?: string;
  additionalInfo?: string;
  error?: string;
  value?: string;
  onBlur?: any;
  onChange?: any;
  isTouched?: boolean;
  position?: string;
}) => {
  const [selected, setSelected] = useState<any>();
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    setOptions(opts);
  }, [opts]);

  useEffect(() => {
    if (!value) {
      setSelected(options[0]);
    } else {
      const filteredOpt = options.filter((opt: any) => opt.name == value)[0];
      setSelected(filteredOpt);
    }
  }, [value, options]);

  useEffect(() => {
    if (onChange) {
      onChange(selected?.value ? selected.name : "");
    }
  }, [selected]);

  const handleBlur = () => {
    onBlur(name);
  };

  return (
    <div className="w-full">
      <Listbox
        as="div"
        value={selected}
        onChange={(e) => {
          setSelected(e);
          functionToRunOnChange(e);
        }}
      >
        {({ open }) => {
          return (
            <div className="relative mt-1 z-100">
              {label && (
                <Listbox.Label>
                  <label
                    htmlFor={name.toLowerCase()}
                    className="inline-flex gap-2 mb-1 text-sm font-medium text-gray-800"
                  >
                    {label}

                    {additionalInfo && (
                      <div className="">
                        <span className="italic text-[10px] text-gray-500">
                          <sup>*</sup>
                          {additionalInfo}
                        </span>
                      </div>
                    )}
                  </label>
                </Listbox.Label>
              )}
              <Listbox.Button
                className={` ${error && "mb-5"} ${
                  error ? "border-red-300" : "border-gray-300"
                } ${
                  error
                    ? "ring-2 ring-offset-1 ring-red-500 focus:ring-red-500"
                    : "focus:ring-blue-500"
                } relative cursor-pointer w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg border focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-gray-300 focus-visible:ring-offset-2 focus-visible:border-gray-300 text-sm text-gray-800 transition-[box-shadow,border] duration-300 delay-200`}
              >
                <span
                  className={` ${
                    !selected?.value && "text-gray-400"
                  } block truncate`}
                >
                  {selected?.name ? selected.name : "Select an option"}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <svg
                    className="w-6 h-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M15.25 10.75L12 14.25L8.75 10.75"
                    ></path>
                  </svg>
                </span>
                <Transition
                  show={error ? true : false}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute mt-3 left-0 text-sm text-red-600 bg-inherit">
                    {error}
                  </div>
                </Transition>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  onBlur={handleBlur}
                  className={`absolute z-[100] top-0 mt-11 list-none w-full py-1 text-left overflow-auto text-sm bg-white rounded-md border-t-0 shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none`}
                >
                  {options.map((option: any, optionIdx: number) => (
                    <Listbox.Option
                      key={optionIdx}
                      className={({ active }) =>
                        `${
                          active
                            ? "text-blue-900 bg-brandBlue/10"
                            : "text-gray-900"
                        }
                          cursor-pointer select-none relative py-2 pl-10 pr-4`
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`${
                              selected ? "font-medium" : "font-normal"
                            } block truncate`}
                          >
                            {option.name}
                          </span>
                          {selected ? (
                            <span
                              className={`${
                                active ? "text-brandBlue" : "text-brandBlue"
                              }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                            >
                              <svg
                                className="w-6 h-6 text-currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                  d="M4.75 12C4.75 7.99594 7.99594 4.75 12 4.75V4.75C16.0041 4.75 19.25 7.99594 19.25 12V12C19.25 16.0041 16.0041 19.25 12 19.25V19.25C7.99594 19.25 4.75 16.0041 4.75 12V12Z"
                                ></path>
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                  d="M9.75 12.75L10.1837 13.6744C10.5275 14.407 11.5536 14.4492 11.9564 13.7473L14.25 9.75"
                                ></path>
                              </svg>
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          );
        }}
      </Listbox>
    </div>
  );
};

export default NewSelect;
