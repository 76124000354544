import { format } from "date-fns";
import { secToTime } from "./timeConversion";
export default (product: any) => {
  const information = [];

  if (product && product.updatedAt) {
    const obj = {
      title: "Last Update",
      value: format(new Date(product.updatedAt), "dd MMMM yyyy"),
    };
    information.push(obj);
  }

  if (product && product.createdAt) {
    const obj = {
      title: "Published",
      value: format(new Date(product.createdAt), "dd MMMM yyyy"),
    };
    information.push(obj);
  }

  if (product && product.version) {
    const obj = {
      title: `${product.category} version`,
      value: product.version.join(", "),
    };
    information.push(obj);
  }

  if (product && product.filesIncluded) {
    const obj = {
      title: `Files Included`,
      value: product.filesIncluded.join(", "),
    };
    information.push(obj);
  }

  if (product && product.universalExpressions) {
    const obj = {
      title: "Universal Expression",
      value: product.universalExpressions,
    };
    information.push(obj);
  }

  if (product && product.clipLengths) {
    const obj = {
      title: `Length`,
      value: product.clipLengths.map((l: number) => secToTime(l)).join(", "),
    };
    information.push(obj);
  }

  if (product && product.resolution) {
    const obj = {
      title: `Resolution`,
      value:
        product.resolution.toLowerCase() === "resizable"
          ? "Resizable"
          : `${product.resolution.split("X")[0]} x ${
              product.resolution.split("X")[1]
            }`,
    };
    information.push(obj);
  }

  if (product && product.fileSize) {
    const obj = {
      title: `File size`,
      value: product.fileSize,
    };
    information.push(obj);
  }

  if (product && product.mediaPlaceholder && product.mediaPlaceholder.length) {
    const obj = {
      title: `Media Placeholders`,
      value: product.mediaPlaceholder.join(", "),
    };
    information.push(obj);
  }

  if (product && product.textPlaceholder && product.textPlaceholder.length) {
    const obj = {
      title: `Media Placeholders`,
      value: product.textPlaceholder.join(", "),
    };
    information.push(obj);
  }

  if (product && product.tags) {
    const obj = {
      title: `Tags`,
      value: product.tags.join(", "),
    };
    information.push(obj);
  }

  return information;
};
