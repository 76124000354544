import { useButton } from "@react-aria/button";
import { useRef } from "react";
import { FocusRing, useLink } from "react-aria";
import { AnimationDefinition, motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";
interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  fillType?: "outline" | "solid";
  children: React.ReactNode;
  removePadding?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

interface IUnstyledButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  removeFocus?: boolean;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  focusStyle?: string;
}

export const Linkbtn = ({
  fillType = "solid",
  children,
  className,
  removePadding = false,
  onClick,
  ...rest
}: IButtonProps) => {
  const ref = useRef<any>(null);
  const controll = useAnimation();

  const enterAnimation: AnimationDefinition = {
    scale: 0.99,
  };

  const leaveAnimation: AnimationDefinition = {
    scale: 1,
    transition: { duration: 0.4 },
  };

  if (fillType === "solid") {
    enterAnimation.backgroundColor = "#4F4E97";
    leaveAnimation.backgroundColor = "#5B5AAA";
  }

  if (fillType === "outline") {
    enterAnimation.backgroundColor = "#4F4E97";
    enterAnimation.color = "#ffffff";
    leaveAnimation.backgroundColor = "#ffffff";
    leaveAnimation.color = "#4F4E97";
  }

  let { linkProps } = useLink(
    {
      //@ts-ignore

      onPressStart: () => {
        controll.stop();
        controll.set(enterAnimation);
      },
      onPressEnd: () => {
        controll.start(leaveAnimation);
      },
    },
    ref
  );
  return (
    <FocusRing focusRingClass="focus:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-inherit focus-visible:ring-indigo-300">
      <Link
        to="/update_product_queue"
        {...linkProps}
        animate={controll}
        //@ts-ignore
        ref={ref}
        {...linkProps}
        {...rest}
        className={`${
          !removePadding && "px-5 py-2.5"
        } text-sm font-medium rounded-xl 
      ${
        fillType === "solid"
          ? `text-white bg-primaryColor border-transparent`
          : `text-primaryColor border-primaryColor`
      }
     
      border select-none touch-none disable-highlight outline-none ${className}`}
      >
        {children}
      </Link>
    </FocusRing>
  );
};

export const PrimaryBtn = ({
  fillType = "solid",
  children,
  className,
  removePadding = false,
  onClick,
  ...rest
}: IButtonProps) => {
  const ref = useRef<any>(null);
  const controll = useAnimation();

  const enterAnimation: AnimationDefinition = {
    scale: 0.99,
  };

  const leaveAnimation: AnimationDefinition = {
    scale: 1,
    transition: { duration: 0.4 },
  };

  if (fillType === "solid") {
    enterAnimation.backgroundColor = "#4F4E97";
    leaveAnimation.backgroundColor = "#5B5AAA";
  }

  if (fillType === "outline") {
    enterAnimation.backgroundColor = "#4F4E97";
    enterAnimation.color = "#ffffff";
    leaveAnimation.backgroundColor = "#ffffff";
    leaveAnimation.color = "#4F4E97";
  }

  let { buttonProps } = useButton(
    {
      //@ts-ignore
      onPress: onClick,
      onPressStart: () => {
        controll.stop();
        controll.set(enterAnimation);
      },
      onPressEnd: () => {
        controll.start(leaveAnimation);
      },
    },
    ref
  );
  return (
    <FocusRing focusRingClass="focus:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-inherit focus-visible:ring-indigo-300">
      <motion.button
        animate={controll}
        //@ts-ignore
        ref={ref}
        {...buttonProps}
        {...rest}
        className={`${
          !removePadding && "px-5 py-2.5"
        } text-sm font-medium rounded-xl 
      ${
        fillType === "solid"
          ? `text-white bg-primaryColor border-transparent`
          : `text-primaryColor border-primaryColor`
      }
     
      border select-none touch-none disable-highlight outline-none ${className}`}
      >
        {children}
      </motion.button>
    </FocusRing>
  );
};

export const SecondaryBtn = ({
  fillType = "solid",
  children,
  className,
  removePadding = false,
  onClick,
  ...rest
}: IButtonProps) => {
  const ref = useRef<any>(null);
  const controll = useAnimation();

  const enterAnimation: AnimationDefinition = {
    scale: 0.99,
  };

  const leaveAnimation: AnimationDefinition = {
    scale: 1,
    transition: { duration: 0.4 },
  };

  if (fillType === "solid") {
    enterAnimation.backgroundColor = "#d1d5db";
    leaveAnimation.backgroundColor = "#e5e7eb";
  }

  if (fillType === "outline") {
    enterAnimation.backgroundColor = "#d1d5db";
    enterAnimation.color = "#4b5563";
    leaveAnimation.backgroundColor = "#ffffff";
    leaveAnimation.color = "#4b5563";
  }

  let { buttonProps } = useButton(
    {
      //@ts-ignore
      onPress: onClick,
      onPressStart: () => {
        controll.stop();
        controll.set(enterAnimation);
      },
      onPressEnd: () => {
        controll.start(leaveAnimation);
      },
    },
    ref
  );
  return (
    <FocusRing focusRingClass="focus:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-inherit focus-visible:ring-indigo-300">
      <motion.button
        animate={controll}
        //@ts-ignore
        ref={ref}
        {...buttonProps}
        {...rest}
        className={`${
          !removePadding && "px-5 py-2.5"
        } text-sm font-medium rounded-xl 
      ${
        fillType === "solid"
          ? `text-gray-600 bg-gray-200 border-transparent`
          : `text-gray-600 border-gray-300`
      }
     
      border select-none touch-none disable-highlight outline-none ${className}`}
      >
        {children}
      </motion.button>
    </FocusRing>
  );
};

export const UnstyledBtn = ({
  children,
  className,
  removeFocus = false, //focus-visible:ring-gray-300
  focusStyle,
  onClick,
  ...rest
}: IUnstyledButtonProps) => {
  const ref = useRef<any>(null);
  const controll = useAnimation();

  const enterAnimation: AnimationDefinition = {
    scale: 0.99,
  };

  const leaveAnimation: AnimationDefinition = {
    scale: 1,
    transition: { duration: 0.4 },
  };

  let { buttonProps } = useButton(
    {
      //@ts-ignore
      onPress: onClick,
      onPressStart: () => {
        controll.stop();
        controll.set(enterAnimation);
      },
      onPressEnd: () => {
        controll.start(leaveAnimation);
      },
    },
    ref
  );
  return (
    <FocusRing
      focusRingClass={`${
        !removeFocus &&
        "focus:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-inherit focus-visible:ring-indigo-300"
      } ${focusStyle}`}
    >
      <motion.button
        animate={controll}
        //@ts-ignore
        ref={ref}
        {...buttonProps}
        {...rest}
        className={`outline-none select-none touch-none disble-highlight ${className}`}
      >
        {children}
      </motion.button>
    </FocusRing>
  );
};
