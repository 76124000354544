import { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/authContext";
import { Home } from "./pages/Home";
import Item from "./pages/Item";
import PayoneerPayments from "./pages/PayoneerPayments";
import SignIn from "./pages/SignIn";
import UnderReview from "./pages/UnderReview";
import UpdateProductQueue from "./pages/UpdateProductQueue";
import UpdateQueueItem from "./pages/UpdateQueueItem";
import PrivateRoutes from "./utils/PrivateRoutes";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/signin" element={<SignIn />} />
              <Route path="/" element={<Home />} />
              <Route path="/under_review" element={<UnderReview />} />
              <Route
                path="/update_product_queue"
                element={<UpdateProductQueue />}
              />
              <Route path="/payoneer" element={<PayoneerPayments />} />

              <Route path="/item/:id" element={<Item />} />
              <Route
                path="/update_queue_item/:id"
                element={<UpdateQueueItem />}
              />
              <Route element={<PrivateRoutes />}></Route>
            </Routes>
          </Suspense>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
