import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import backend from "../backend";
import Table from "../components/Table";
import Pagination from "../components/inputs/Pagination";

const UpdateProductQueue = () => {
  const navigate = useNavigate();
  const [underReviewProducts, setUnderReviewProducts] = useState<any>(null);
  const [status, setStatus] = useState("under review");
  const [count, setCount] = useState<any>(null);
  const [page, setPage] = useState(1);

  const getUnderReviewProducts = async () => {
    const { data: products } = await backend.get(
      `/update_product_queue/get_admin_products?page=${page}&per_page=20`
    );
    if (products) {
      setUnderReviewProducts(products);
    }
  };

  const getCount = async () => {
    const { data: result } = await backend.post(`/products/count_admin`, {
      page,
      per_page: 20,
      status: status,
    });
    setCount(result);
  };

  const headings = [
    "Nos",
    "name",
    "category",
    "author",
    "createdAt",
    "type",
    "actions",
  ];

  const formatForTable = (datas: any) => {
    let result: any = [];
    datas.forEach((data: any, idx: number) => {
      let obj: any = {};
      headings.forEach((heading) => {
        obj.Nos = {
          value: ((page - 1) * 20 + idx + 1).toString(),
        };
        if (heading === "actions") {
          obj[heading] = {
            value: "View",
            button: true,
            onClick: () =>
              navigate(
                `/update_queue_item/${data.id}?originalId=${data.originalProduct.id}`
              ),
          };
        } else if (heading === "type") {
          obj[heading] = {
            value:
              data.originalProduct.softRejectedCount > 0
                ? `<span className="bg-yellow-200 text-yellow-800 p-1 px-2 rounded-md">Soft Rejected</span>`
                : `<span className="bg-green-200 text-green-800 p-1 px-2 rounded-md">For Update</span>`,
          };
        } else if (heading === "createdAt") {
          obj[heading] = {
            value: format(new Date(data[heading]), "dd MMMM yyyy"),
          };
        } else if (heading === "author") {
          obj[heading] = {
            value: data.author.userName,
          };
        } else if (heading === "name") {
          obj[heading] = {
            value: data.originalProduct[heading],
          };
        } else if (heading === "category") {
          obj[heading] = {
            value: data.originalProduct[heading],
          };
        }
      });
      result.push(obj);
    });
    return result;
  };

  useEffect(() => {
    if (status) {
      getUnderReviewProducts();
      getCount();
    }
  }, [status, page]);

  return (
    <div className="w-full p-10 max-w-screen-2xl mx-auto">
      <div className="w-full flex items-center justify-between mb-10">
        <h1 className="text-xl font-bold text-gray-800">
          Products In Update Queue
        </h1>
      </div>

      {underReviewProducts && (
        <Table
          headings={headings}
          datas={formatForTable(underReviewProducts)}
        />
      )}

      {count && (
        <div className="mt-5">
          <Pagination
            currentPage={page}
            numberOfPages={count.pagination.numberOfAvailablePages}
            setCurrentPage={(e) => {
              setPage(e);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default UpdateProductQueue;
