import { createContext, useContext, useEffect, useState } from "react";
import backend from "../backend";

const AuthContext = createContext(null);

export const useAuth = (): any => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: any }) => {
  const [currentUser, setCurrentUser] = useState<any>(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    getCurrentUser(token);
  }, []);

  const getCurrentUser = async (token: string | null) => {
    try {
      if (!currentUser) {
        if (token) {
          const { data } = (await backend.get("users/whoami")) as any;
          setCurrentUser(data);
        }
      } else {
        return currentUser;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const signIn = async (signInParams: any) => {
    const url: string = `${process.env.REACT_APP_BACKEND_URL}/users/signin_admin`;
    const { data: user } = await backend.post(url, {
      userNameOrEmail: signInParams.userNameOrEmail,
      password: signInParams.password,
    });
    setCurrentUser(user);
    localStorage.setItem("token", user.token);
  };

  const signOut = () => {
    localStorage.removeItem("token");
    setCurrentUser(null);
  };

  const value: any = {
    currentUser,
    setCurrentUser,
    getCurrentUser,
    signIn,
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
