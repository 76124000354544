import React from "react";

interface IContainerProps
  extends React.PropsWithChildren,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > {
  className?: string | undefined;
}

const Container = ({ children, className, ...rest }: IContainerProps) => {
  return (
    <div
      {...rest}
      className={`px-3 md:px-10 2xl:px-0 2xl:max-w-[72.5rem] mx-auto ${className}`}
    >
      {children}
    </div>
  );
};

export default Container;
