import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface IPageBtnProps {
  page: number;
  onClick: () => any;
  currentPage: number;
}

const PageBtn = ({ page, onClick, currentPage }: IPageBtnProps) => {
  return (
    <button
      onClick={onClick}
      className={`${
        currentPage === page
          ? "md:-mt-[3px] pt-3  md:border-brandBlue"
          : "md:border-transparent"
      } p-3 px-4 text-sm border-2 rounded-lg md:rounded-none md:border-b-0 md:border-l-0 md:border-r-0 md:border-t-2 font-medium text-gray-800 focus:outline-none focus-visible:font-medium focus-visible:border-gray-500`}
    >
      {page}
    </button>
  );
};

interface IPaginationProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  numberOfPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => any;
  className?: string;
}
const Pagination = ({
  numberOfPages,
  currentPage,
  setCurrentPage,
  className,
}: IPaginationProps) => {
  const navigate = useNavigate();
  const [pages, setPages] = useState(1);

  useEffect(() => {
    setPages(numberOfPages);
  }, [numberOfPages]);

  const handleNext = () => {
    if (currentPage !== pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (pages > 1) {
    return (
      <div
        className={`w-full md:border-t border-gray-300 flex items-center justify-between ${className}`}
      >
        <button
          onClick={() => {
            handlePrevious();
          }}
          className="inline-flex items-center bg-gray-200 hover:bg-brandBlue hover:text-white rounded-md px-2 py-1 text-gray-700 focus:outline-none"
        >
          <svg className="w-5 h-5 text-current" fill="none" viewBox="0 0 24 24">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M10.25 6.75L4.75 12L10.25 17.25"
            ></path>
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M19.25 12H5"
            ></path>
          </svg>
          <span className="hidden md:block ml-2 text-sm font-medium">
            Previous
          </span>
        </button>
        <div className="flex items-center justify-center flex-wrap">
          {pages <= 10 &&
            Array.from(Array(pages).keys()).map((page) => (
              <PageBtn
                key={page}
                page={page + 1}
                currentPage={currentPage}
                onClick={() => {
                  setCurrentPage(page + 1);
                }}
              />
            ))}

          {pages > 10 &&
            currentPage < 6 &&
            Array.from(Array(pages).keys())
              .slice(0, 10)
              .map((page) => (
                <PageBtn
                  key={page}
                  page={page + 1}
                  currentPage={currentPage}
                  onClick={() => {
                    setCurrentPage(page + 1);
                  }}
                />
              ))}

          {pages > 10 && currentPage < 6 && (
            <>
              <div className="p-3 px-4 text-sm text-gray-800 focus:outline-none focus-visible:-mt-[1px]  focus-visible:border-t-2 focus-visible:font-medium focus-visible:border-gray-500">
                ...
              </div>
              <PageBtn
                page={pages}
                currentPage={currentPage}
                onClick={() => {
                  setCurrentPage(pages);
                }}
              />
            </>
          )}

          {/* between 6 and 10 */}
          {pages > 10 && currentPage >= 6 && currentPage <= pages - 8 && (
            <>
              <PageBtn
                page={1}
                currentPage={currentPage}
                onClick={() => {
                  setCurrentPage(1);
                }}
              />

              <div className="p-3 px-4 text-sm text-gray-800 focus:outline-none focus-visible:-mt-[1px]  focus-visible:border-t-2 focus-visible:font-medium focus-visible:border-gray-500">
                ...
              </div>

              {Array.from(Array(pages).keys())
                .slice(currentPage - 4, currentPage + 4)
                .map((page) => (
                  <PageBtn
                    key={page}
                    page={page + 1}
                    currentPage={currentPage}
                    onClick={() => {
                      setCurrentPage(page + 1);
                    }}
                  />
                ))}

              <div className="p-3 px-4 text-sm text-gray-800 focus:outline-none focus-visible:-mt-[1px]  focus-visible:border-t-2 focus-visible:font-medium focus-visible:border-gray-500">
                ...
              </div>

              <PageBtn
                page={pages}
                currentPage={currentPage}
                onClick={() => {
                  setCurrentPage(pages);
                }}
              />
            </>
          )}

          {/* after 10 */}
          {pages > 10 && currentPage >= 6 && currentPage > pages - 8 && (
            <>
              <PageBtn
                page={1}
                currentPage={currentPage}
                onClick={() => {
                  setCurrentPage(1);
                }}
              />

              <div className="p-3 px-4 text-sm text-gray-800 focus:outline-none focus-visible:-mt-[1px]  focus-visible:border-t-2 focus-visible:font-medium focus-visible:border-gray-500">
                ...
              </div>

              {Array.from(Array(pages).keys())
                .slice(pages - 9, pages)
                .map((page) => (
                  <PageBtn
                    key={page}
                    page={page + 1}
                    currentPage={currentPage}
                    onClick={() => {
                      setCurrentPage(page + 1);
                    }}
                  />
                ))}
            </>
          )}
        </div>

        <button
          onClick={() => {
            handleNext();
          }}
          className="inline-flex items-center bg-gray-200 hover:bg-brandBlue hover:text-white rounded-md px-2 py-1 text-gray-700 focus:outline-none"
        >
          <span className="hidden md:block mr-2 text-sm font-medium ">
            Next
          </span>

          <svg className="w-5 h-5 text-current" fill="none" viewBox="0 0 24 24">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M13.75 6.75L19.25 12L13.75 17.25"
            ></path>
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M19 12H4.75"
            ></path>
          </svg>
        </button>
      </div>
    );
  } else {
    return null;
  }
};

export default Pagination;
